.signup {
  width: 100%;
  padding-bottom: 150px;
}

.signup__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
}

.signup__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 12px;
  position: relative;
}

.signup__inputs-box {
  width: 100%;
  display: grid;
  grid-template-columns: 172px 1fr;
  gap: 14px;
}

.signup__submit-btn_disabled {
  /* background: var(--primary-disabled); */
  opacity: 0.5 !important;
  user-select: none;
  pointer-events: none;
}

.signup .label_date {
  top: 20px;
  user-select: none;
  /* top: 203px; */
}

.signup .label_date_active {
  top: 20px;
  top: 208px;
  user-select: none;
}

.auth-input_date {
  position: absolute;
  top: 203px;
}

.signup .animated-gradient {
  background: repeating-linear-gradient(to right, #FFB31F 0%, #FFDB95 50%, #FFB31F 100%);
  width: 100%;
  background-size: 200% auto;
  background-position: 0 100%;
  animation: gradient 2s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  color: transparent;
  height: 100%;
}