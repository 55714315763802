.business-card {
    width: 100%;
    border-radius: 8px;
    background: #2B2B3F;
    backdrop-filter: blur(2px);
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 132px;
    padding: 8px;
    box-sizing: border-box;
    min-height: 124px;
}

.business-card__img {
    border-radius: 6px;
    width: 50px;
    height: 66px;
    object-fit: cover;
    user-select: none;
    min-width: 50px;
    position: relative;
    z-index: 1;
}

.business-card__info {
    display: flex;
    width: 100%;
    gap: 8px;
    user-select: none;
}

.business-card__img-box {
    width: 50px;
    height: 66px;
}

.business-card__info__title {
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 116.667% */
    padding-bottom: 4px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    max-width: 100px;
    user-select: none;
}

.business-card__info__farm {
    color: #8B8D9B;
    text-align: right;
    font-family: "Nunito Sans";
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 175% */
    display: flex;
    align-items: center;
    gap: 2px;
    margin-top: 2px;
    user-select: none;
}

.business-card__info__farm__number {
    color: #8B8D9B;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    /* 116.667% */
    user-select: none;
}

.business-card__info__farm__number_white {
    color: #FFF;
    user-select: none;
}

.business-card__price-box {
    position: relative;
    width: 100%;
    padding: 8px 10px 0 10px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    gap: 19px;
    user-select: none;
}

.business-card__info__farm__coin_white {
    fill: #FFF;
    user-select: none;
}

.business-card__price-box__level {
    color: #8B8D9B;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* 128.571% */
    white-space: nowrap;
    user-select: none;
}

.business-card__price-box__level_white {
    color: #FFF;
    user-select: none;
}

.business-card__price-box__coin {
    /* margin-left: 19px; */
    text-align: center;
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* 112.5% */
    display: flex;
    align-items: center;
    gap: 4px;
    margin: auto;
    user-select: none;
}

.business-card__price-box__line {
    position: absolute;
    top: 0;
    width: 109%;
    left: -8px;

    height: 1px;
    background-color: #3E3E55;
    user-select: none;
}

.business-card__price-box__icon {
    width: 14px;
    height: 14px;
    user-select: none;
}

.business-card__img_animated-gradient {
    background: repeating-linear-gradient(to right, #1E1E2D 0%, #2B2B3F 50%, #1E1E2D 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    color: transparent;

    border-radius: 6px;
    width: 50px;
    height: 66px;
    object-fit: cover;
    user-select: none;
    min-width: 50px;
}

.business-card__info_animated-gradient {
    background: repeating-linear-gradient(to right, #1E1E2D 0%, #2B2B3F 50%, #1E1E2D 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    color: transparent;
    max-height: 15px;
    border-radius: 6px;
}

.business-card__price-box_animated-gradient {
    background: repeating-linear-gradient(to right, #1E1E2D 0%, #2B2B3F 50%, #1E1E2D 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    color: transparent;
}

.business-card__img_default-animated-gradient {
    background: repeating-linear-gradient(to right, #1E1E2D 0%, #2B2B3F 50%, #1E1E2D 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    color: transparent;

    position: absolute;
    top: 8px;
    left: 8px;
    width: 50px;
    height: 66px;
    border-radius: 6px;
    z-index: 2;
    opacity: 1;
    transition: opacity ease-in-out 0.2s;
}

.business-card__img_default-animated-gradient-under {
    z-index: 0 !important;
    opacity: 0;
    transition: opacity ease-in-out 0.2s;
}

@media (max-width:370px) {
    /* .business-card__img {
        width: 40px;
        min-width: 40px;
    } */

    .business-card__price-box {
        gap: 15px;
    }
}