.income-popup {
    background: rgba(30, 30, 45, 0.80);
    backdrop-filter: blur(4px);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 995;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.income-popup_visible {
    z-index: 994;
}

.income-popup_unvisible {
    z-index: 0;
}

.income-popup__coin {
    width: 130px;
    height: 130px;
    z-index: 100;
    user-select: none;
}

.income-popup__coin-ligthing {
    width: 141px;
    height: 141px;
    top: 35%;
    position: absolute;
    z-index: 98;
    background-color: #FFB31F;
    filter: blur(52px);
    user-select: none;
}

.income-popup__text {
    color: #FFF;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 111.111% */
    z-index: 99;
    margin-top: 20px;
    user-select: none;
}

.income-popup__number {
    color: #FFF;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    /* 108.696% */
    text-transform: uppercase;
    z-index: 99;
    margin-top: 10px;
    user-select: none;
}

.income-popup__btn {
    color: #1E1E2D;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* 112.5% */
    border-radius: 10px;
    background: #FFB31F;
    padding: 12px 0px;
    width: 102px;
    z-index: 99;
    margin-top: 30px;
    cursor: pointer;
}