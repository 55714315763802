.phone-popup {
    z-index: 997;
}

.phone-popup .templ-popup__close {
    display: none;
}

.phone-popup__bg {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    background: rgba(0, 0, 0, 0.59);
    z-index: 996;
    cursor: pointer;
}

.phone-popup__icon {
    background: linear-gradient(180deg, #C5882E 3.68%, #F8BB45 100%);
    border-radius: 50%;
}

.phone-popup__text {
    color: #FFF;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    margin-top: 16px;
    padding-bottom: 22px;
    max-width: 80%;
    cursor: pointer;
    user-select: none;
}

.phone-popup__btn {
    color: #1E1E2D;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 120% */
    border-radius: 16px;
    background: #FFB31F;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px 20px 0px;
    width: 100%;

    user-select: none;
    cursor: pointer;
}