.menu-card {
    width: 100%;
    border-radius: 8px;
    background: #2B2B3F;
    backdrop-filter: blur(2px);
    padding: 8px 6px 12px 6px;
    box-sizing: border-box;

    /* height: 300px; */
    min-height: 264px;
}

.menu-card__level {
    color: #FFB31F;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    /* 116.667% */
}

.menu-card__img {
    width: 100%;
    height: 100%;
    /* max-height: 140px; */
    /* margin-top: 8px; */
    border-radius: 6px;
   
    object-fit: cover;
}

.menu-card__title {
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 114.286% */
    margin-top: 12px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 185px;
}

.menu-card__parameters {
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    /* 116.667% */
    margin-top: 4px;
    min-height: 14px;
}

.menu-card__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 14px;
}

.menu-card__info__line {
    height: 2px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    border-radius: 16px;
    background-color: #3B3B52;
}

.menu-card__info-number {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-card__info-number__current-level {
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 116.667% */
}

.menu-card__info-number__max-level {
    color: #8B8D9B;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
}

.menu-card__info-number__farm {
    color: #8B8D9B;
    text-align: right;
    font-family: "Nunito Sans";
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 175% */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
}

.menu-card__info-number__farm__price {
    color: #8B8D9B;
    font-family: "Nunito Sans";
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 116.667% */
    margin-left: 6px;
}

.menu-card__img-box {
    position: relative;
    margin-top: 8px;
    border-radius: 6px;
    aspect-ratio: 1 / 1;
    width: 100%;
}

.menu-card .animated-gradient {
    position: absolute;
    z-index: 2;
    left: 0px;
    top: 0px;
    right: 0px;

    background: repeating-linear-gradient(to right, #1E1E2D 0%, #2B2B3F 50%, #1E1E2D 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;

    /* margin-top: 8px; */
    border-radius: 6px;
    height: 100%;
    opacity: 1;
    transition: opacity ease-in-out 0.2s;
}

.menu-card_animated-gradient {
    background: repeating-linear-gradient(to right, #1E1E2D 0%, #2B2B3F 50%, #1E1E2D 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;

    height: 140px;
    margin-top: 22px;
    border-radius: 6px;
}

.menu-card__text_animated-gradient {
    background: repeating-linear-gradient(to right, #1E1E2D 0%, #2B2B3F 50%, #1E1E2D 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    height: 70px;
    margin-top: 12px;
    border-radius: 6px;
}

.menu-card .animated-gradient_under {
    z-index: 0 !important;
    opacity: 0;
    transition: opacity ease-in-out 0.2s;
}

@keyframes gradient {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -200% 0;
    }
}