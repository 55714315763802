.count-coin {
  width: 100%;
  max-width: 390px;
  position: fixed;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;
  z-index: 0;

  user-select: none;
}

.count-coin_scroll {
  position: absolute
}

.coin {
  background: linear-gradient(36deg, #D6A65D 9.88%, #F8BB45 85.8%);
  border-radius: 50%;
}

.main__coin_visible {
  opacity: 1;
  position: relative;
  /* top: -470px; */
}

.main__coin_unvisible {
  opacity: 0;
}

.count-coin_business {
  top: -130px;
}

.count-coin_business .main__coin {
  font-size: 16px;
}

.count-coin_business .main__coin-icon {
  width: 16px;
  height: 16px;
}