.rating-card {
    display: grid;
    grid-template-columns: 38px 42px 2fr 0.5fr 1fr;

    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #2B2B3F;
    align-items: center;
}

.rating-card__number {
    color: #8B8D9B;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 150% */
}

.rating-card__name {
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 128.571% */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.rating-card__visits {
    color: #8B8D9B;
    text-align: right;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 150% */
    text-align: center;
}

.rating-card__coin {
    color: #FFF;
    text-align: right;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* 150% */
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 4px;
}

.rating-card__icon {
    width: 16px;
    height: 16px;
}

.rating-card:last-of-type {
    border-bottom: none;
}

.rating-card__img {
    width: 30px;
    height: 30px;
}