.popup-level-up {
    z-index: 993;
}

.popup-level-up__text {
    color: #FFF;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    /* 107.692% */
    letter-spacing: 1.04px;
    text-transform: uppercase;
    margin-top: 16px;
    user-select: none;
}

.popup-level-up__btn {
    border-radius: 16px;
    background: #FFB31F;
    display: flex;
    width: 100%;
    max-width: 350px;
    padding: 20px 0px;
    justify-content: center;
    align-items: center;
    color: #1E1E2D;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 120% */
    margin-top: 30px;

    cursor: pointer;
}