.main {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 400px;
    position: relative;
    /* overflow-x: hidden; */
    padding-bottom: 90px;
}

.main__img_cosmo {
    max-width: 78%;
    margin-top: 20px;
    position: relative;
    /* padding-bottom: 108px; */
    z-index: 10;
    user-select: none;
    border-radius: 50%;
}

.main__bg {
    position: absolute;
    /* opacity: 0.4; */
    mix-blend-mode: plus-lighter;
    bottom: -20px;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100vh;
    /* top: -106px; */
}

.main__tasks-and-btn-rewards {
    display: flex;
    gap: 4px;
    align-self: flex-end;
    width: 100%;
}

.main__tasks-box {
    display: flex;
    flex-direction: row-reverse;
    /* display: grid; */
    grid-template-columns: 1fr 1fr 1fr;
    width: calc(99% - 54px);
    column-gap: 4px;
    max-height: 155px;
    z-index: 2;
    /* direction: rtl; */
    /* min-width: 308px; */
}

.main-task__personal {
    border-radius: 8px;
    border: 1px solid #D6A65D;
    background: #2B2B3F;
    backdrop-filter: blur(2px);

    display: flex;
    align-items: center;
    justify-content: center;
}

.main-task__personal .main-task__value {
    text-align: center;
    color: #FFF;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    /* 116.667% */

    user-select: none;
}

.main-task__personal .main-task__img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: #D6A65D 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-task__personal .main-task__price {
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    /* 116.667% */
    user-select: none;
}

.main__coin-box {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    height: 28px;
}

.main__coin {
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 107.692% */
    text-transform: uppercase;

    position: absolute;
    top: 280px;
    /* left: 218px; */

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.main__task_rewards {
    border-radius: 8px;
    background: #2B2B3F;
    backdrop-filter: blur(2px);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 10px 8px 10px;
    box-sizing: border-box;
    z-index: 88;
    cursor: pointer;
    max-width: 54px;
}

.main__task_rewards_visible {
    opacity: 1;
}

.main__task_rewards_unvisible {
    opacity: 0;
}

.main__task__title_rewards {
    color: #FFF;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
    /* 100% */
    margin-top: 30px;
    padding-bottom: 6px;
}

.main__tap-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    min-height: 300px;
}

.main__tap {
    border-radius: 8px;
    background: rgba(43, 43, 63, 0.80);
    backdrop-filter: blur(2px);
    display: inline-flex;
    padding: 4px 10px 4px 8px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    gap: 10px;

    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    /* 107.692% */
    text-transform: uppercase;

    position: absolute;
    width: fit-content;
    top: 342px;
    /* left: 309px; */
    right: 20px;
    z-index: 11;
}

.main__clickable {
    border-radius: 60px;
    background: #8E49FE;
    display: inline-flex;
    padding: 4px 8px;
    box-sizing: border-box;
    width: fit-content;
    justify-content: center;
    align-items: center;
    gap: 10px;

    position: absolute;
    top: 45px;
    z-index: 11;
    left: 207px;

    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    user-select: none;
    pointer-events: none;
    /* 120% */
    white-space: nowrap;
}

.main__cleckable__triangle {
    position: absolute;
    left: -3px;
    top: 10px;
}

.main__tap-energy {
    /* position: absolute; */
    /* top: 550px;
    left: 50px; */

    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    text-transform: uppercase;

    display: flex;
    align-items: center;
    gap: 6px;

    padding-bottom: 30px;
    width: 80%;
    justify-content: flex-start;
    margin-top: -20px;
}

.main__tap-message {
    position: absolute;
    top: 0;
    left: 0;
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    /* 107.692% */
    text-transform: uppercase;
    z-index: 12;
}

.main__animate-tap {
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-transform: uppercase;
    position: absolute;
    z-index: 1000;
    animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.main-task__gradient {
    /* position: absolute; */
    z-index: 2;
    left: 0px;
    top: 0px;
    right: 0px;

    background: repeating-linear-gradient(to right, #1E1E2D 0%, #2B2B3F 50%, #1E1E2D 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;

    /* margin-top: 8px; */
    border-radius: 6px;
    height: 100%;
    width: 100%;
}

.main__task_rewards_animated-gradient {
    background: repeating-linear-gradient(to right, #1E1E2D 0%, #2B2B3F 50%, #1E1E2D 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    color: transparent;
    user-select: none;
    height: 100%;
    cursor: none;
}

.main__task_rewards_animated-gradient svg,
.main__task_rewards_animated-gradient p {
    opacity: 0;
}

@keyframes gradient {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -200% 0;
    }
}