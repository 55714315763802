.menu {
    position: relative;
    padding-bottom: 150px;

    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 400px;
    z-index: 2;

    scroll-padding-top: 100px;
    /* overflow-x: hidden; */
    background-color: #1E1E2D;
}

.menu__title {
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 122.222% */
    text-transform: uppercase;
    padding-top: 13px;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    position: sticky;
    top: -1px;
    width: 100%;
    background-color: #1E1E2D;
    z-index: 98;
}

.menu__cards-box {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
}

.menu__cards-grid {
    /* margin-top: 12px; */
    column-gap: 6px;
    row-gap: 6px;
    width: 100%;
    max-width: 400px;
    display: grid;
    grid-template-columns: repeat(2, minMax(0, 1fr));
    grid-template-rows: max-content;
    grid-auto-rows: max-content;

    border: 1px solid rgba(255, 255, 255, 0.08);
    padding: 12px;
    box-sizing: border-box;
    border-radius: 12px;
}

.menu__blur {
    position: fixed;
    bottom: -1px;
    background: linear-gradient(0deg, #20202F 12.82%, rgba(32, 32, 47, 0.00) 93.83%);
    right: 0;
    height: 162px;
    width: 100%;
    user-select: none;
    pointer-events: none;
}

.popup-menu__text {
    color: #FFF;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    margin-top: 16px;
    padding-bottom: 68px;
}

.subcategory-nav__box_animated-gradient {
    width: 100%;
    height: fit-content;
    padding: 12px 0 16px 0px;
}

.subcategory-nav_animated-gradient {
    background: repeating-linear-gradient(to right, #1E1E2D 0%, #2B2B3F 50%, #1E1E2D 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    border-radius: 80px;

    height: 30px;
    box-sizing: border-box;
}