.popup-level-info {
    padding-top: 50px;
}

.popup-level-info__table-box {
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 12px;
    padding: 12px;
    width: 100%;
    max-width: 300px;
}

.popup-level-info__table {
    display: grid;
    grid-template-columns: repeat(3, minMax(0, 1fr));
    border-top: 1px solid rgba(255, 255, 255, 0.18);
    padding: 8px 13px;
    align-items: center;
}

.popup-level-info__table-box__header {
    display: grid;
    grid-template-columns: repeat(3, minMax(0, 1fr));
    margin-bottom: 6px;
    padding: 0 13px;
}

.popup-level-info__table__level {
    color: #8B8D9B;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 114.286% */
}

.popup-level-info__table__balance {
    color: #8B8D9B;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 114.286% */
}

.popup-level-info__table__img {
    max-width: 26px;
}