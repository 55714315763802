.profile {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 400px;
    padding-bottom: 150px;
    margin-top: 18px;
    z-index: 2;
}

.profile__img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    user-select: none;
}

.profile__coin-box {
    display: flex;
    align-items: center;
    justify-content: center;

    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    text-transform: uppercase;
    gap: 8px;
    user-select: none;
}

.profile__coin {
    width: 20px;
    height: 20px;
}

.profile__header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    position: relative;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.profile__level {
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 166.667% */
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.profile__level-number {
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    text-transform: uppercase;
    user-select: none;
}

.profile__img-box {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.profile__name {
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    /* 116.667% */
    margin-top: 8px;

    user-select: none;
}

.profile_id {
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 111.111% */
    user-select: none;
}

.profile__btn {
    color: #FFB31F;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 140% */

    display: flex;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 44px;
    background: rgba(43, 43, 63, 0.51);

    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 200;

    cursor: pointer;
}

.profile__nav-block {
    display: flex;
    width: 100%;
    /* justify-content: space-between; */
    justify-content: space-around;
    border-radius: 12px;
    background: #2B2B3F;
    backdrop-filter: blur(2px);
    padding: 2px;

    position: sticky;
    top: 12px;
    z-index: 98;

    box-shadow: 10px 5px 8px #1E1E2D, -5px -4px 8px 0px #1E1E2D;
    backdrop-filter: blur(5px);

    margin-top: 12px;
}

.profile__nav {
    padding: 14px 18px;
    border-radius: 10px;
    backdrop-filter: blur(2px);
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #FFF;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 140% */
    transition: all 0.5s ease-in-out;
    max-height: 46px;
    user-select: none;
}

.profile__nav_active {
    background: #1A1A28;
    /* font-size: 12px; */
    font-weight: 700;
    transition: all 0.5s ease-in-out;
    user-select: none;
}

.profile__title {
    color: #FFF;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 122.222% */
    letter-spacing: 0.72px;
    text-transform: uppercase;
    margin-top: 22px;
    width: 100%;
    user-select: none;
}

.profile__title_empty {
    margin-top: 50px;
    user-select: none;
}


.profile__grid {
    margin-top: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 6px;
}

.profile__blur {
    position: fixed;
    bottom: -1px;
    background: linear-gradient(0deg, #20202F 12.82%, rgba(32, 32, 47, 0.00) 93.83%);
    right: 0;
    height: 162px;
    width: 100%;
    user-select: none;
    pointer-events: none;
}