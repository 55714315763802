.airdrop {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 400px;
    position: relative;
    overflow-x: hidden;
    z-index: 2;
    margin: 12px 0 0;
}

.airdrop__icon {
    width: 120px;
    height: 120px;
    margin: 0 auto;
}

.airdrop__title {
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 122.222% */
    text-transform: uppercase;
    padding: 12px 0;
    text-align: center;

    user-select: none;
}

.airdrop__info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.airdrop__info-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 16px;
    background: #2B2B3F;
    align-items: center;
    padding: 16px 10px;
}

.airdrop__info-box_tg {
    justify-content: center;
    gap: 20px;
}

.airdrop__info-box_main {
    background-color: unset;
    justify-content: center;
    gap: 16px;
}

.airdrop__info-title {
    color: #FFF;

    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}

.airdrop__info-value {
    color: #FFF;

    font-family: "Mayak";
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 16px;
}

.airdrop__req-title {
    color: #FFF;
    padding: 12px 0;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    margin: 12px 0 0;
}