.for-pc {
    /* position: absolute; */
    /* top: 0;
    left: 0; */
    /* z-index: 2001; */
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--bg-color);
    /* justify-content: flex-end; */

    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    padding: 30px 0 30px 0;
    justify-content: space-between;
    align-items: center;

    user-select: none;
    z-index: 10000;
}

.for-pc__text {
    color: #FFB31F;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 123.077% */
    margin-top: 30px;
}

.for-pc__title {
    color: #FFF;
    text-align: center;

    text-shadow: 0px 3px 0px #FFB31F;
    -webkit-text-strokeWidth: 2;
    -webkit-text-stroke-color: #FFDB95;
    font-family: Mayak;
    font-size: 100px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 202px; 202% */
    letter-spacing: 2px;
    text-transform: uppercase;

    display: flex;
    flex-direction: column;
    gap: 14px;
    position: relative;
    z-index: 1;
    margin-top: 20px;
}

.for-pc__title_second {
    margin-top: -60px;
    text-align: center;
}

.for-pc__bg {
    user-select: none;
    pointer-events: none;
    position: absolute;
    width: 100%;
    max-width: 500px;
    top: 100px;
    /* max-height: 90vh; */
    object-fit: cover;
    z-index: 0;
    /* background: linear-gradient(180deg, #1E1E2D 0%, rgba(30, 30, 45, 0.00) 19.37%, rgba(30, 30, 45, 0.00) 41.11%, #1E1E2D 79.05%), url(<path-to-image>) lightgray 0px -92.934px / 100% 113.548% no-repeat; */
    mix-blend-mode: lighten;
}

.for-pc__terms {
    color: #ABADBF;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 114.286% */
    margin-top: -20px;
}

.img_for-pc {
    user-select: none;
    pointer-events: none;
    position: absolute;
    width: 100%;
    max-width: 500px;
    top: 0;
    /* max-height: 90vh; */
    object-fit: cover;
    z-index: 0;
}

.for-pc__phone {
    margin-top: 100px;
}