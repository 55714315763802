.subcat-nav {
  width: 100vw;
  /* position: fixed; */
  max-width: 430px;
  position: sticky;
  top: 32px;

  /* background-color: #262637; */
  z-index: 98;
  overflow: hidden;
}

.subcat-nav__swiper {
  /* max-width: 400%; */
  width: 100%;
  height: 100%;
  max-height: 70px;
  background: #1e1e2d;
  box-sizing: border-box;
}

.subcat-nav__swiper .swiper-slide {
  width: fit-content !important;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 12px 0 16px 0px;
  margin-left: 10px;
}

/* .subcat-nav__slide_size_wide {
  width: calc(100vw - 38px);
} */

.subcat-nav__link {
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 80px;
  background: #262637;
  box-sizing: border-box;
  padding: 8px 12px;
  gap: 4px;
}

.subcat-nav__link_active {
  pointer-events: none;
  user-select: none;
}

.subcat-nav__mark-box {
  width: 0;
  display: flex;
  transition: all 0.2s ease-in-out;
}

.subcat-nav__mark-box_visible {
  width: 6px;
}

.subcat-nav__link-mark {
  width: 6px;
  aspect-ratio: 1/1;
  border-radius: 100%;
  background: #ffb31f;
}

.subcat-nav__link-text {
  color: #8B8D9B;
  line-height: 116.667%;
  transition: color 0.2s ease-in-out;

  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  /* 116.667% */
}

.subcat-nav__link_active .subcat-nav__link-text {
  color: #fff;
}

.subcat-nav__slide_size_wide {
  padding-right: 12px;
}