.business {
    padding-bottom: 150px;

    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 400px;
    z-index: 2;
}

.business__title {
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    /* 122.222% */
    text-transform: uppercase;
    padding: 12px 0;
    text-align: center;

    user-select: none;
    /* position: sticky;
    top: -1px;
    z-index: 99;
    background-color: #1E1E2D;
    width: 100%; */
}

.business__cards-grid {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    gap: 6px;
    width: 100%;
    max-width: 400px;
    margin-top: 14px;

    align-items: center;
    justify-items: center;
    justify-content: center;
}

.business__blur {
    position: fixed;
    bottom: -1px;
    background: linear-gradient(0deg, #20202F 12.82%, rgba(32, 32, 47, 0.00) 106.17%);
    right: 0;
    height: 162px;
    width: 100%;
    user-select: none;
    pointer-events: none;
}

.popup-submit__price {
    display: flex;
    align-items: center;
    gap: 5px;
    user-select: none;
}

.business__nav-block {
    display: flex;
    width: 100%;
    /* justify-content: space-between; */
    justify-content: space-around;
    border-radius: 12px;
    background: #2B2B3F;
    backdrop-filter: blur(2px);
    padding: 2px;

    position: sticky;
    top: 12px;
    z-index: 98;

    box-shadow: 10px 5px 8px #1E1E2D, -5px -4px 8px 0px #1E1E2D;
    backdrop-filter: blur(5px);
    min-height: 46px;
    user-select: none;
    margin-top: 50px;
}

.business__nav-block_more-two {
    min-height: 46px;
}

.business__nav-block_more-four {
    min-height: 36px;
}

.business__nav {
    /* padding: 0px 18px; */
    border-radius: 10px;
    backdrop-filter: blur(2px);
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;

    color: #FFF;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 140% */
    user-select: none;
}

.business__nav_more-two {
    font-size: 10px;
}

.business__nav_more-four {
    font-size: 8px;
}

.business__nav_active {
    border-radius: 10px;
    background: #1A1A28;
    backdrop-filter: blur(2px);
    transition: all 0.2s ease-in-out;

    box-sizing: border-box;
}

.business__nav-block_animated-gradient{
    background: repeating-linear-gradient(to right, #1E1E2D 0%, #2B2B3F 50%, #1E1E2D 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    color: transparent;
    height: 100%;
    min-height: 36px;
}