.gift-popup-box {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    background: #1E1E2D;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
}

.gift-popup {}

.gift-popup_visible {
    z-index: 999;
}

.gift-popup_unvisible {
    z-index: 0;
}

.gift-popup__text_white {
    color: #FFF;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 111.111% */
    user-select: none;
}

.gift-popup__text_orange {
    color: #D6A65D;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 107.692% */
    margin-top: 6px;
    user-select: none;
}

.gift-popup__icon {
    width: 28px;
    height: 28px;
    user-select: none;
}

.gift-popup__coin {
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 107.692% */
    text-transform: uppercase;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 8px;
    user-select: none;
}

.gift-popup__btn {
    border-radius: 16px;
    background: #FFB31F;
    color: #1E1E2D;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 120% */
    width: 100%;
    padding: 20px 0px;
    margin-top: 40px;
    cursor: pointer;
}

.gift-popup__bg {
    position: absolute;
    bottom: 0px;
    z-index: 998;
}