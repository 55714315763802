.main-task {
    border-radius: 8px;
    background: #2B2B3F;
    backdrop-filter: blur(2px);
    width: 33%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px;
    box-sizing: border-box;
    gap: 6px;
    max-height: 142px;
}

.main-task__title {
    color: #FFF;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    /* 116.667% */

    user-select: none;
}

.main-task_rewards {
    border-radius: 8px;
    background: #2B2B3F;
    backdrop-filter: blur(2px);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 10px 8px 10px;
    box-sizing: border-box;
}

.main-task__title_rewards {
    color: #FFF;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
    /* 100% */
    margin-top: 30px;
    padding-bottom: 6px;

    user-select: none;
}

.main-task__timer {
    display: flex;
    color: #8B8D9B;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    /* 120% */
    align-items: center;
    justify-content: center;
    gap: 4px;

    user-select: none;
}

.main-task__value {
    color: #FFF;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    /* 116.667% */
    text-align: left;
    width: 100%;

    user-select: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* width: 88px; */
    direction: ltr;
}

.main-task__value_profile {
    width: unset;
    overflow: initial;
    white-space: inherit;
}

.main-task__img {
    width: 100%;
    border-radius: 6px;
    height: 70px;
    object-fit: contain;
    background-color: #35354C;
}

.main-task__price {
    /* max-width: 27px; */
    height: 14px;
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: center;
    /* margin-top: 6px; */
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    /* 116.667% */

    user-select: none;
}

.main-task__price__icon {
    width: 10px;
    height: 10px;
}

.main-task__use-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.main-task__use {
    color: #8B8D9B;
    text-align: right;
    font-family: "Nunito Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    /* 140% */

    user-select: none;
}

.main-task_animated-gradient {
    background: repeating-linear-gradient(to right, #1E1E2D 0%, #2B2B3F 50%, #1E1E2D 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    color: transparent;

    user-select: none;
}

.main-task_animated-gradient p,
.main-task_animated-gradient img,
.main-task_animated-gradient div {
    opacity: 0;
    display: none;
}

@media (max-width:385px) {
    .main-task__value {
        width: 69px;
    }
}