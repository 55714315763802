.menu-card-popup__img {
    width: 86px;
    aspect-ratio: 1 / 1;
    border-radius: 12px;
    object-fit: cover;
}

.menu-card-popup__title {
    color: #FFF;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 109.091% */
    margin-top: 16px;
}

.menu-card-popup__current-info-box {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-top: 8px;

    color: #FFF;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* 112.5% */
}

.menu-card-popup__current-info-box__farm {
    display: flex;
    align-items: center;
    gap: 2px;

    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* 112.5% */
}

.menu-card-popup__table-box {
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 6px 12px 0 12px;
    box-sizing: border-box;
    margin-top: 16px;
}

.menu-card-popup__table-box__header {
    display: grid;
    grid-template-columns: 1fr 0.5fr 0.5fr 0.8fr;
    width: 100%;

    color: #8B8D9B;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    padding-bottom: 6px;
}

.menu-card-popup__table-box__header__title {
    color: #8B8D9B;
    font-family: "Nunito Sans";
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 175% */
}

.menu-card-popup__table__line {
    display: grid;
    grid-template-columns: 1fr 0.5fr 0.5fr 0.8fr;
    width: 100%;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    padding: 8px 13px;
    box-sizing: border-box;

    color: #8B8D9B;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 114.286% */
}

.menu-card-popup__table__text_align-end {
    text-align: end;
    display: flex;
    align-items: center;
    justify-content: end;
}

.menu-card-popup__table__text_align-center {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-card-popup__table__line__arrow {
    color: #8B8D9B;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    /* 114.286% */
}

.menu-card-popup__table__line__coin {
    width: 12px;
    height: 12px;
    margin-right: 2px;
}

.menu-card-popup__table__line_current {
    color: #FFF;
}